<template>
    <div>
        <hb-blade-header half class="task-center-header" @close="close(true)" :back-button-off="true">

            <template v-if="!$vuetify.breakpoint.xs">
                <help-juice blade-header />
            </template>
            <template v-slot:title>
                <div class="d-flex align-center">
                    <hb-icon color="#101318" :customSize="28" class="mr-2">mdi-playlist-check</hb-icon> Task Center
                </div>
            </template>
        </hb-blade-header>
        <div class="pad-16px">
            <div class="d-flex justify-space-between px-4">
                <div class="task-filters">
                    <hb-autocomplete rounded v-model="selectedFilter" item-text="name" item-value="id" @change="fetchTasks" :items="filtersList" placeholder="Select Type"
                        :clearable="false" width="244px" return-object style="display:inline-block">
                    </hb-autocomplete>
                </div>
                <div class="task-btns">
                    <hb-icon class="task-btn-icon hand" @click="createTaskShowDialogEvent">mdi-plus</hb-icon>
                    <!-- <hb-icon class="task-btn-icon hand" @click="showFilterDialog = true">mdi-tune</hb-icon> -->
                    <div class="filter-icon task-btn-icon hand" @click="showFilterDialog = true">
                        <span class="filter-count" v-if="filtersCount">{{ filtersCount }}</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3051_193812" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                                width="24" height="24">
                                <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3051_193812)">
                                <path
                                    d="M17.75 20.25C16.7 20.25 15.8125 19.8875 15.0875 19.1625C14.3625 18.4375 14 17.55 14 16.5C14 15.45 14.3625 14.5625 15.0875 13.8375C15.8125 13.1125 16.7 12.75 17.75 12.75C18.8 12.75 19.6875 13.1125 20.4125 13.8375C21.1375 14.5625 21.5 15.45 21.5 16.5C21.5 17.55 21.1375 18.4375 20.4125 19.1625C19.6875 19.8875 18.8 20.25 17.75 20.25ZM17.75 18.25C18.2333 18.25 18.6458 18.0792 18.9875 17.7375C19.3292 17.3958 19.5 16.9833 19.5 16.5C19.5 16.0167 19.3292 15.6042 18.9875 15.2625C18.6458 14.9208 18.2333 14.75 17.75 14.75C17.2667 14.75 16.8542 14.9208 16.5125 15.2625C16.1708 15.6042 16 16.0167 16 16.5C16 16.9833 16.1708 17.3958 16.5125 17.7375C16.8542 18.0792 17.2667 18.25 17.75 18.25ZM4 17.5V15.5H12V17.5H4ZM6.25 11.25C5.2 11.25 4.3125 10.8875 3.5875 10.1625C2.8625 9.4375 2.5 8.55 2.5 7.5C2.5 6.45 2.8625 5.5625 3.5875 4.8375C4.3125 4.1125 5.2 3.75 6.25 3.75C7.3 3.75 8.1875 4.1125 8.9125 4.8375C9.6375 5.5625 10 6.45 10 7.5C10 8.55 9.6375 9.4375 8.9125 10.1625C8.1875 10.8875 7.3 11.25 6.25 11.25ZM6.25 9.25C6.73333 9.25 7.14583 9.07917 7.4875 8.7375C7.82917 8.39583 8 7.98333 8 7.5C8 7.01667 7.82917 6.60417 7.4875 6.2625C7.14583 5.92083 6.73333 5.75 6.25 5.75C5.76667 5.75 5.35417 5.92083 5.0125 6.2625C4.67083 6.60417 4.5 7.01667 4.5 7.5C4.5 7.98333 4.67083 8.39583 5.0125 8.7375C5.35417 9.07917 5.76667 9.25 6.25 9.25ZM12 8.5V6.5H20V8.5H12Z"
                                    fill="#637381" />
                            </g>
                        </svg>

                    </div>
                    <hb-icon class="task-btn-icon">mdi-dots-vertical</hb-icon>
                </div>
            </div>
            <div class="d-flex justify-space-between pt-4 px-4">
                <v-menu offset-y v-for="(item, index) in taskBuckets" :key="index">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="task-bucket" @click="clickedMenu(index)"
                            :class="{ 'bucket-active': index === activeMenu }">
                            <div class="bucket-icon">
                                <hb-icon color="#101318">{{ item.icon }}</hb-icon>
                                <div class="tasks-counter" v-if="getBucketCount(item)">{{ getBucketCount(item) }}</div>
                            </div>
                            <p class="hb-font-caption-medium mb-0">{{ item.title }}</p>
                        </div>
                    </template>
                    <v-list v-if="taskTypes && Object.keys(taskTypes).length" style="max-height: 300px; overflow: auto;">
                        <v-list-item v-for="i in taskTypes[item.value]" :ripple="false"
                            @click="showFiltered({ parent: item, child: i })">
                            <v-list-item-title class="pr-6">{{ i.name }} 
                                <span v-if="getTypeCount(i, item.value)" class="menu-task-count">
                                    {{ getTypeCount(i, item.value) }}
                                </span>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <div class="d-flex justify-space-between pt-4 px-4" v-if="activeFilter">
                <div class="filter-title">
                    <hb-icon v-if="activeFilter.icon">{{ activeFilter.icon }}</hb-icon>
                    <p class="hb-font-body-medium mb-0 ml-2">{{ activeFilter.title }}</p>
                    <div class="tasks-counter-filter ml-2" v-if="activeFilter.count">{{ activeFilter.count }}</div>
                </div>
                <div class="clear-filter-btn">
                    <span class="mr-1 hb-font-caption-medium">clear</span>
                    <hb-icon small class="hand" @click="clearFilterEvent">mdi-close-circle</hb-icon>
                </div>
            </div>

            <div v-if="!isFetchingTasks && tasksList.length" class="tasks-list mt-4 px-4" :style="{ maxHeight: tasksListheight, overflowY: 'auto' }">
                <hb-task v-for="item in tasksList" 
                    class="mb-3" 
                    :type="item.type"
                    :hot-task="item.hotTask"
                    :icon="item.icon | getIcon"
                    :title="item.title"
                    :time="item.time | formatDateServices"
                    :assigned-to="item.assignedTo"
                    :created-by="item.createdBy" 
                    :created-by-time="item.createdByTime | formatLocalDateTimeServices"
                    :completed-by="item.completedBy ? item.completedBy : ''"
                    :completed-by-time="item.completedByTime | formatLocalDateTimeServices" 
                    :updated-by="item.updatedBy" 
                    :updated-by-time="item.updatedByTime | formatLocalDateTimeServices"
                    :attachments="item.attachments ? item.attachments : ''" 
                    :space="item.space"
                    :spaceType="item.space ? item.spaceType : ''"
                    :expanded.sync="item.expanded"
                    :contact-name="item.contactName" 
                    :contact-status="item.contactStatus"
                    :property="item.property" 
                    button-one="Update"
                    :button-one-loading="isLoadingTask"
                    @button-one-click="updateTaskEvent(item)" 
                    button-two="Reassign" 
                    @button-two-click="showAssignDialog = true"
                    :status="item.status"
                    :timer-green-value="item.timerGreenValue"
                    :timer-red-value="item.timerRedValue"
                    @click="showDetailsEvent(item)">
                        {{ item.details }}
                </hb-task>
            </div>

            <v-skeleton-loader
                v-if="isFetchingTasks"
                v-for="i in 3"
                type="list-item-avatar, list-item-two-line"
                class="mt-3 px-4"
            ></v-skeleton-loader>

            <div class="empty-complete" v-if="!isFetchingTasks && !tasksList.length && !hasError">
                <span v-if="filtersCount">
                    <div class="filter-no-record">
                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3081_235068" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                                <rect width="100" height="100" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_3081_235068)">
                                <path d="M73.9557 84.375C69.5807 84.375 65.8828 82.8646 62.862 79.8437C59.8411 76.8229 58.3307 73.125 58.3307 68.75C58.3307 64.375 59.8411 60.6771 62.862 57.6563C65.8828 54.6354 69.5807 53.125 73.9557 53.125C78.3307 53.125 82.0286 54.6354 85.0495 57.6563C88.0703 60.6771 89.5807 64.375 89.5807 68.75C89.5807 73.125 88.0703 76.8229 85.0495 79.8437C82.0286 82.8646 78.3307 84.375 73.9557 84.375ZM73.9557 76.0417C75.9696 76.0417 77.6884 75.3299 79.112 73.9062C80.5356 72.4826 81.2474 70.7639 81.2474 68.75C81.2474 66.7361 80.5356 65.0174 79.112 63.5937C77.6884 62.1701 75.9696 61.4583 73.9557 61.4583C71.9418 61.4583 70.2231 62.1701 68.7995 63.5937C67.3759 65.0174 66.6641 66.7361 66.6641 68.75C66.6641 70.7639 67.3759 72.4826 68.7995 73.9062C70.2231 75.3299 71.9418 76.0417 73.9557 76.0417ZM16.6641 72.9167V64.5833H49.9974V72.9167H16.6641ZM26.0391 46.875C21.6641 46.875 17.9661 45.3646 14.9453 42.3437C11.9245 39.3229 10.4141 35.625 10.4141 31.25C10.4141 26.875 11.9245 23.1771 14.9453 20.1562C17.9661 17.1354 21.6641 15.625 26.0391 15.625C30.4141 15.625 34.112 17.1354 37.1328 20.1562C40.1536 23.1771 41.6641 26.875 41.6641 31.25C41.6641 35.625 40.1536 39.3229 37.1328 42.3437C34.112 45.3646 30.4141 46.875 26.0391 46.875ZM26.0391 38.5417C28.053 38.5417 29.7717 37.8299 31.1953 36.4063C32.6189 34.9826 33.3307 33.2639 33.3307 31.25C33.3307 29.2361 32.6189 27.5174 31.1953 26.0937C29.7717 24.6701 28.053 23.9583 26.0391 23.9583C24.0252 23.9583 22.3064 24.6701 20.8828 26.0937C19.4592 27.5174 18.7474 29.2361 18.7474 31.25C18.7474 33.2639 19.4592 34.9826 20.8828 36.4063C22.3064 37.8299 24.0252 38.5417 26.0391 38.5417ZM49.9974 35.4167V27.0833H83.3307V35.4167H49.9974Z" fill="#A5B4BF"/>
                            </g>
                        </svg>
                    </div>
                    <div class="complete-text">No Task Found!</div>
                    <hb-btn color="secondary" small @click="clearFiltersEvent">Clear filters</hb-btn>
                </span>
                <span v-else>
                    <hb-icon color="#A5B4BF" :customSize="100">mdi-emoticon-excited-outline</hb-icon>
                    <div class="complete-text">All task are completed!</div>
                </span>
            </div>
        </div>
        <create-task :task-sub-types="taskTypes" :task-assignees="taskAssignees" :fetch-tasks="fetchTasks" v-model="showDialog" :task-details="taskDetails" />
        <tasks-filter ref="filterModal" :task-sub-types="taskTypes" :task-assignees="taskAssignees" :fetch-tasks="fetchTasks" v-model="showFilterDialog" />
        <update-system-task v-model="showAssignDialog" />
        <task-details :task-details="singleTaskData" :fetch-tasks="fetchTasks" :primaryRoleProperties="primaryRoleProperties" v-model="showDetailsDialog" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '../../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';

export default {
    name: "TaskCenter",
    mixins: [notificationMixin],
    data() {
        return {
            menuOption: [],
            activeMenu: '',
            pending: [],
            tempTask: [],
            urlEventIds: {},
            disableButtons: false,
            isLoaded: false,
            gTasksCount: '',
            filteredTasks: [],
            showFilters: false,
            showDismissModal: false,
            showCompleteAllModal: false,
            showDialog: false,
            showFilterDialog: false,
            filtersList: [
                {id: '', name: 'All Tasks'}, 
                {id: 'due_today', name: 'Due Today'}, 
                {id: 'overdue', name: 'Overdue'}, 
                {id: 'due_tomorrow', name: 'Due Tomorrow'}
            ],
            selectedFilter: {id: '', name: 'All Tasks'},
            expanded: false,
            taskListsHeight: '100%',
            taskBuckets: [
                {
                    value: 'lead',
                    icon: 'mdi-account-plus',
                    title: 'Lead'
                },
                {
                    value: 'delinquecy',
                    icon: 'mdi-shield-star',
                    title: 'Delinquency'
                },
                {
                    value: 'operations',
                    icon: 'mdi-settings',
                    title: 'Operations'
                },
                {
                    value: 'maintenance',
                    icon: 'mdi-tools',
                    title: 'Maintenance'
                }
            ],
            activeFilter: null,
            isSelected: false,
            showAssignDialog: false,
            showDetailsDialog: false,
            tasksList: [],
            taskTypes: {},
            taskDetails: null,
            taskAssignees: [],
            filtersCount: 0,
            isFetchingTasks: false,
            singleTaskData: null,
            isLoadingTask: false,
            countData: {
                lead: null,
                delinquecy: null,
                operations: null,
                maintenance: null,
                other: null
            },
            intervalValue: null,
            hasError: false,
        }
    },
    async created() {
        this.fetchTasks();
        this.fetchAssignees();
        await this.fetchTaskTypes();
        this.fetchTasksCount();
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    computed: {
        ...mapGetters({
            primaryRoleProperties: 'propertiesStore/primaryRoleProperties'
        }),
        tasksListheight() {
            let filter = this.activeFilter
            let documentHeight = document.body.clientHeight;
            if(documentHeight) return (documentHeight - (315 + (filter ? 32 : 0))) + 'px';
            return '100%';
        }
    },
    methods: {
        async fetchTaskTypes() {
            let response = await api.get(this, api.TASKS_CENTER + 'task_types');
            console.log('response :>> ', response);
            let allowedTypes = {
                "Lead": ["New Web Reservation","Reservation Follow-Up","New Web Lead","Lead Follow-Up","New Reservation","Hot Lead - Pending Website Move-In","Cart Abandonment","Coupon Clicks","Website Reservation Failure","Pending Move In","Pending Transfer","Other"],
                "Delinquecy": ["Collection Calls","Overlock Space","Lock Removal","Lien Notices","Lock Cut","Cut Lock and Space Inventory","Auction Notice Advertisement","Schedule Auction","Auction Day","Auction Payment","Lien Notice","Lock Cut Inventory","Other"],
                "Operations": ["Review Rate Change Notification","Approve Rate Change","Move Out","Generated Documents","Rental Lease","Welcome Letter"],
                "Maintenance": ["Clean & Prepare Space"]
            }

            Object.keys(allowedTypes).forEach(keyItem => {
                allowedTypes[keyItem].forEach(atItem => {
                    let foundType = null;
                    if (atItem === 'Other') {
                        foundType = response.task_types.find(item => item.name === atItem && item.label === keyItem);
                        console.log('foundType :>> ', atItem, foundType, keyItem)
                    } else foundType = response.task_types.find(item => item.name === atItem)
                    if(foundType) {
                        if (this.taskTypes[keyItem.toLowerCase()]) {
                            this.taskTypes[keyItem.toLowerCase()].push(foundType)
                        } else this.taskTypes[keyItem.toLowerCase()] = [foundType]
                    }
                })
            })
        },

        async fetchTasksCount() {
            let types = Object.values(this.taskTypes).flatMap(item => item);
            let payload = {
                "task_labels": [
                    "Task Generic",
                    "Delinquecy",
                    "Lead",
                    "Automated Communication",
                    "Maintenance & Space Prep"
                ],
                "task_type_ids": types.map(item => { return { id: item.id}})
            }
            let { tasks_counts } = await api.post(this, api.TASKS_CENTER + 'count', payload);
            this.countData =  {
                lead: {
                    count: tasks_counts.labels_tasks_counts.find(item => item.task_type_label === "Lead"),
                    typesCount: tasks_counts.types_tasks_counts.filter(item => item.task_type_label === "Lead")
                },
                delinquecy: {
                    count: tasks_counts.labels_tasks_counts.find(item => item.task_type_label === "Delinquecy"),
                    typesCount: tasks_counts.types_tasks_counts.filter(item => item.task_type_label === "Delinquecy")
                },
                operations: {
                    count: tasks_counts.labels_tasks_counts.find(item => item.task_type_label === "Automated Communication"),
                    typesCount: tasks_counts.types_tasks_counts.filter(item => item.task_type_label === "Automated Communication")
                },
                maintenance: {
                    count: tasks_counts.labels_tasks_counts.find(item => item.task_type_label === "Maintenance & Space Prep"),
                    typesCount: tasks_counts.types_tasks_counts.filter(item => item.task_type_label === "Maintenance & Space Prep")
                }
            }
            console.log('response fetchTasksCount :>> ', tasks_counts, this.countData)
        },

        getTypeCount(task_type, bucket) {
            let type = this.countData[bucket]?.typesCount && this.countData[bucket]?.typesCount.length ? this.countData[bucket].typesCount.find(item => item.task_type_id === task_type.id) : null
            return type ? type.all_tasks : 0
        },
        getBucketCount(bucket) {
            let count = this.countData[bucket.value]?.count ? this.countData[bucket.value]?.count?.all_tasks : 0
            return count;
        },

        async fetchTasks(params = null, isFiltering = true) {
            try {
                console.log('params :>> ', params);
                this.hasError = false;
                this.isFetchingTasks = true;
                if (isFiltering) {
                    if (params && Object.keys(params).length && !params.hasOwnProperty('id')) {
                        this.filtersCount = Object.keys(params).length;
                        this.showFilterDialog = false;
                    } else this.filtersCount = 0;
                }
                let filters = {
                    limit: 20, 
                    offset: 0,
                    ...(this.selectedFilter && this.selectedFilter.id && {statuses: [this.selectedFilter.id]}),
                    ...((this.filtersCount || !isFiltering) && params)
                }
                let response = await api.post(this, api.TASKS_CENTER + 'all-tasks', filters);
                this.tasksList = response.tasks.map(item => {
                    let association = '';
                    let assignees = '';
                    let property = '';
                    let attachments = [];
                    if (item.Associations?.contact?.length) association = 'contact';
                    else if(item.Associations?.space?.length) association = 'space';
                    else if(item.Associations?.property?.length) association = 'property';
                    else association = undefined;
                    if (item.Assignees && (item.Assignees.Contacts?.length || item.Assignees.Roles?.length)) {
                        let contacts = item.Assignees.Contacts.map(item => item && item.first + ' ' + item.last)
                        let roles = item.Assignees.Roles.map(item => item && item.name);
                        assignees = [...contacts, ...roles].join(', ');
                    } else assignees = 'Everyone'
                    if (association === 'property') {
                        let foundProperty = item.Associations?.property[0];
                        property = (foundProperty.number ? foundProperty.number + ' - ' : '') + (foundProperty.city ? foundProperty.city + ' - ' : '') + foundProperty.name;
                    }
                    if (association === 'space' && this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.space[0].property_id)) {
                        let foundProperty = this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.space[0].property_id);
                        property = (foundProperty.number ? foundProperty.number + ' - ' : '') + (foundProperty.city ? foundProperty.city + ' - ' : '') + foundProperty.name;
                    }
                    if (association === 'contact' 
                        && item.Associations?.contact[0].Leases 
                        && item.Associations?.contact[0].Leases.length 
                        && item.Associations?.contact[0].Leases[0]?.Unit?.property_id
                        && this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.contact[0].Leases[0]?.Unit?.property_id)) {
                        let foundProperty = this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.contact[0].Leases[0]?.Unit?.property_id);
                        property = (foundProperty.number ? foundProperty.number + ' - ' : '') + (foundProperty.city ? foundProperty.city + ' - ' : '') + foundProperty.name;
                    }
                    if (item.Attachments?.length) {
                        let images = item.Attachments.filter(item => item.mimetype.includes('image')).length;
                        let videos = item.Attachments.filter(item => item.mimetype.includes('video')).length;
                        let files = item.Attachments.filter(item => item.mimetype.includes('pdf')).length;
                        if (images) attachments.push(images + (images > 1 ? ' images' : ' image'));
                        if (videos) attachments.push(videos + (videos > 1 ? ' videos' : ' video'));
                        if (files) attachments.push(files + (files > 1 ? ' attachments' : ' attachment'));
                    }
                    let taskObject = {
                        id: item.id,
                        type: association,
                        hotTask: item.hot_task ? true : false,
                        icon: item.TaskType.icon,
                        title: item.TaskType.name,
                        time: item.due_date,
                        assignedTo: assignees,
                        createdBy: item.created_by_details ? item.created_by_details.first + ' ' + item.created_by_details.last : '',
                        createdByTime: item.created_at,
                        updatedBy: item.modified_by_details ? item.modified_by_details.first + ' ' + item.modified_by_details.last : '', 
                        updatedByTime: item.modified_at,
                        attachments: attachments.length ? attachments.join(', ') : '',
                        space: association === 'space' ? item.Associations?.space[0].number : association === 'contact' && property ? item.Associations?.contact[0].Leases[0]?.Unit.number  : '',
                        spaceType: 'storage',
                        expanded: false,
                        contactName: association === 'contact' ? item.Associations.contact[0].first + ' ' + item.Associations.contact[0].last : '',
                        contactStatus: item.Associations?.contact[0] ? item.Associations?.contact[0].status : '',
                        property: property,
                        details: item.details,
                        status: item.hot_task && this.getTaskColor(item.start_date).color === 'green' ? 'timer green' : item.hot_task && this.getTaskColor(item.start_date).color === 'red' ? 'timer red' : item.status === 'completed' ? 'complete' : '',
                        timerGreenValue: item.hot_task && this.getTaskColor(item.start_date).color === 'green' ? this.getTaskColor(item.start_date).text : '',
                        timerRedValue: item.hot_task && this.getTaskColor(item.start_date).color === 'red' ? this.getTaskColor(item.start_date).text : '',
                    };
                    if (item.status === 'completed') {
                        taskObject = {
                            ...taskObject,
                            completedBy: item.modified_by_details ? item.modified_by_details.first + ' ' + item.modified_by_details.last : '',
                            completedByTime: item.modified_at
                        }
                    }
                    return taskObject
                })
                this.intervalValue = setInterval(() => {
                    this.updateTime();
                }, 1000)
            } catch (error) {
                this.showMessageNotification({type: 'error', description: error});
                this.hasError = true;
            } finally {
                this.isFetchingTasks = false;
            }
        },

        updateTime() {
            this.tasksList.forEach(item => {
                item = {
                    ...item,
                    status: this.getTaskColor(item.start_date).color === 'green' ? 'timer green' : this.getTaskColor(item.start_date).color === 'red' ? 'timer red' : item.status === 'completed' ? 'complete' : '',
                    timerGreenValue: this.getTaskColor(item.start_date).color === 'green' ? this.getTaskColor(item.start_date).text : '',
                    timerRedValue: this.getTaskColor(item.start_date).color === 'red' ? this.getTaskColor(item.start_date).text : '',
                }
            })
        },

        getTaskColor(params) {
            const dbTime = moment.utc(params);
            const diffMinutes = moment().utc().diff(dbTime, "minutes");
            const diffHours = moment().utc().diff(dbTime, "hours");
            const diffDays = moment().utc().diff(dbTime, "days");
            let text ='', color = '';
            if (diffDays > 0) {
                text = `${diffDays} days ago`;
                color = "red";
            }
            else if (diffHours > 0) {
                text = `${diffHours} hrs ago`;
                color = "red";
            }
            else if(diffMinutes > 5) {
                text = `${diffMinutes} mins ago`;
                color = "red";
            } else {
                text = `${diffMinutes} mins ago`;
                color = "green";
            }
            return { text, color };
        },

        closeFilter() {
            this.showFilters = false;
        },
        showTaskDialog() {
            this.closeFilter();
            setTimeout(() => {
                this.showDialog = true;
            }, 20);
        },
        close(exit = true) {
            // if(exit){
            //     this.setTaskCenterOpen(false);
            // }
            this.$emit('close');
        },
        clickedMenu(params) {
            this.activeMenu = params;
        },
        handleClickOutside() {
            if (!this.activeFilter) {
                this.activeMenu = '';
            }
        },
        createTaskShowDialogEvent() {
            this.taskDetails = null;
            this.showDialog = true;
        },
        showFiltered(params) {
            this.activeFilter = null;
            let { parent, child } = params;
            this.$nextTick(() => {
                this.activeFilter = {
                    id: '',
                    icon: parent.icon,
                    title: child.name,
                    count: this.getTypeCount(child, parent.value)
                }
                this.fetchTasks({
                    task_type_ids: [{ id: child.id }]
                }, false);
            });
        },
        clearFilterEvent() {
            this.activeFilter = null;
            this.fetchTasks();
        },
        async updateTaskEvent(params) {
            try {
                this.isLoadingTask = true;
                let response = await api.get(this, api.TASKS_CENTER + params.id);
                let { tasks: task } = response;
                const taskType = Object.values(this.taskTypes).flatMap(item => item).find(itemInner => itemInner.id === task.task_type_id);
                let mediaFiles = [];
                let documents = [];
                let assigness = [...task.Assignees.Contacts.map(item => {
                    return {
                        id: item.id,
                        name: item.first + ' ' + item.last,
                        is_admin: true
                    }
                }), ...task.Assignees.Roles.map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        is_role: true
                    }
                })]
                let property = [];
                let association = '';
                if (task.Associations?.contact?.length) association = 'contact';
                else if(task.Associations?.space?.length) association = 'space';
                else if(task.Associations?.property?.length) association = 'property';
                if (association === 'property') {
                    property = task.Associations?.property[0];
                }
                if (association === 'space' && this.primaryRoleProperties.find(itemInner => itemInner.id === task.Associations?.space[0].property_id)) {
                    let foundProperty = this.primaryRoleProperties.find(itemInner => itemInner.id === task.Associations?.space[0].property_id);
                    property = foundProperty;
                }
                if (association === 'contact' 
                    && task.Associations?.contact[0].Leases 
                    && task.Associations?.contact[0].Leases.length 
                    && task.Associations?.contact[0].Leases[0]?.Unit?.property_id
                    && this.primaryRoleProperties.find(itemInner => itemInner.id === task.Associations?.contact[0].Leases[0]?.Unit?.property_id)) {
                    let foundProperty = this.primaryRoleProperties.find(itemInner => itemInner.id === task.Associations?.contact[0].Leases[0]?.Unit?.property_id);
                    property = foundProperty;
                }
                if (task.Associations?.space?.length) {
                    property = this.primaryRoleProperties.find(itemInner => itemInner.id === task.Associations?.space[0].property_id);
                }

                if (task.Attachments?.length) { 
                    for (const element of task.Attachments) {
                        if (element.mimetype.includes('image') || element.mimetype.includes('video')) {
                            let videoThumbnail = '';
                            let isVideo = element.mimetype.includes('video');
                            if (isVideo) {
                                videoThumbnail = await this.createThumbnailFromBase64(element.data_url)
                            }
                            mediaFiles.push({
                                "id": element.id,
                                video: isVideo ? true : false,
                                video_src: `data:${element.mimetype};base64,${element.data_url}`,
                                src: isVideo && videoThumbnail ? videoThumbnail : `data:${element.mimetype};base64,${element.data_url}`,
                                thumbnail: videoThumbnail,
                                upload_id: element.upload_id
                            })
                        } else {
                            documents.push(element)
                        }
                    }
                }
                this.taskDetails = {
                    id: task.id,
                    type: taskType && taskType.label.toLowerCase(),
                    subType: taskType && taskType.id,
                    title: task.title,
                    assignTo: assigness,
                    association: task.Associations?.contact?.length || task.Associations?.lease?.length ? 3 : task.Associations?.space?.length ? 2 : task.Associations?.property?.length ? 1 : 0,
                    selectedProperties: property ? [property] : [],
                    details: task.details,
                    hotTask: task.hot_task ? true : false,
                    dueDate: task.due_date ? task.due_date.split(' ')[0] : '',
                    startDate: task.start_date,
                    mediaFiles,
                    attachments: documents,
                    location: task.task_location === 'inside' ? 0 : 1,
                    associations: task.Associations?.space?.length ? task.Associations?.space : task.Associations?.contact?.length ? task.Associations?.contact : task.Associations?.property?.length ? task.Associations?.property : null,
                    status: task.status
                };
                this.showDialog = true;
            } catch (error) {
                this.showMessageNotification({type: 'error', description: error});
            } finally {
                this.isLoadingTask = false;
            }
        },
        createThumbnailFromBase64(base64Video) {
            return new Promise((resolve, reject) => {
                const video = document.createElement("video");
                video.src = URL.createObjectURL(new Blob([Uint8Array.from(atob(base64Video), c => c.charCodeAt(0))], { type: "video/mp4" }));
                video.muted = true;
                video.autoplay = true;
                video.playsInline = true;

                video.onloadeddata = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = 300;
                    canvas.height = (video.videoHeight / video.videoWidth) * 300;
                    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
                    resolve(canvas.toDataURL("image/png"));
                };

                video.onerror = () => reject("Error loading video");
            });
        },
        assignClick() {
            this.showAssignDialog = true;
        },
        async fetchAssignees(){
            let usersRequest = api.get(this, api.ADMIN);
            let rolesRequest = api.get(this, api.ROLES);
            Promise.all([usersRequest, rolesRequest])
            .then(responses => {
                this.taskAssignees = [...responses[0].admins.map( item => { 
                    return {
                        id: item.contact_id,
                        name: item.first + ' ' + item.last,
                        is_admin: true
                    }
                }), ...responses[1].roles.map( item => {
                    return {
                        id: item.id,
                        name: item.name,
                        is_role: true
                    }
                })];
            })
        },
        async showDetailsEvent(params) {
            let response = await api.get(this, api.TASKS_CENTER + params.id);
            let { tasks: task } = response;
            if (task.Attachments?.length) {
                task.mediaFiles = [];
                for (const element of task.Attachments) {
                    if (element.mimetype.includes('image') || element.mimetype.includes('video')) {
                        let videoThumbnail = '';
                        let isVideo = element.mimetype.includes('video');
                        if (isVideo) {
                            videoThumbnail = await this.createThumbnailFromBase64(element.data_url)
                        }
                        task.mediaFiles.push({
                            "id": element.id,
                            video: isVideo ? true : false,
                            video_src: `data:${element.mimetype};base64,${element.data_url}`,
                            src: isVideo && videoThumbnail ? videoThumbnail : `data:${element.mimetype};base64,${element.data_url}`,
                            thumbnail: videoThumbnail,
                            upload_id: element.upload_id
                        })
                    }
                }
            }
            this.singleTaskData = task;
            this.showDetailsDialog = true;
            console.log('response :>> ', this.singleTaskData)
        },
        clearFiltersEvent() {
            this.$refs.filterModal.filterData = {
                assignees: [],
                association: 0,
                selectedProperties: [],
                associations: [],
                createdByList: [],
                statuses: [],
                types: []
            };
            this.fetchTasks()
        }
    },
    components: {
        'hb-task': () => import('../../../aviary/HbTask.vue'),
        'create-task': () => import('./CreateTask.vue'),
        'tasks-filter': () => import('./TaskFilterModal.vue'),
        'update-system-task': () => import('./UpdateSystemTask.vue'),
        'task-details': () => import('./TaskDetails.vue'),
    },
    watch: {
        // activeView(value){
        //     if (value !== 'view') {
        //         if (value === 'moveIn') {
        //             this.fetchPending();
        //         }
        //         else if (value !== 'seeList') {
        //             this.getTasks();
        //         }
        //     } else {
        //         this.tempTask = [];
        //         this.resetTasks();
        //     }
        // },
        // eventTypes(value) {
        //     if (value) {
        //    //     this.setIds();
        //     }
        // }
    },
    destroyed() {
        // this.resetTasks();
        clearInterval(this.intervalValue);
        EventBus.$off('hide_drawer', this.close);
    },
}
</script>
<style lang="scss">
@media (max-width: 600px) {
    .task-center-panel {
        .hb-blade-header-wrapper {
            .hb-blade-header-card-wrapper {
                .hb-blade-header-wrapper-title div {
                    display: flex !important;
                    flex: unset !important;
                }

                div:nth-child(1) {
                    padding-left: 0px;
                    justify-content: flex-start !important;
                    position: relative;
                    left: 5px;
                    flex: 2 0 70%;
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
<style scoped lang="scss">
.bs-none {
    box-shadow: none !important;
}

.tasks-counter {
    background-color: #F26500;

    >div {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 14px;
        color: #fff;

        small {
            font-style: normal;
            font-size: 10px;
            line-height: 16px;
            font-weight: 500;
        }
    }
}

.task-calender {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #101318;
}

.pad-16px {
    // padding-left: 13px;
    // padding-right: 13px;
    padding-top: 20px;
    // padding-bottom: 8px;
}

.task-btns {
    .task-btn-icon {
        padding: 8px;
        display: inline-block;
        padding-top: 0;
    }
}

.task-bucket {
    width: 89px;
    height: 89px;
    padding: 8px;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid #DFE3E8;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.bucket-active {
        border: 2px solid #00848E;
    }

    .bucket-icon {
        padding: 8px;
        border-radius: 20px;
        background: #F4F6F8;
        position: relative;

        &:focus {
            background-color: #F26500;
        }

        .tasks-counter {
            padding: 1px 7px;
            border-radius: 20px;
            background: #F26500;
            color: #FFFFFF;
            font-size: 10px;
            font-weight: 500;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.menu-task-count {
    border-radius: 20px;
    background: #F26500;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    line-height: 15px;
    position: absolute;
    right: 16px;
    padding: 1px 7px;
}

.tasks-counter-filter {
    padding: 1px 7px;
    border-radius: 20px;
    background: #F26500;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    top: 0;
    right: 0;
}

.filter-title {
    display: flex;
    align-items: center;
}

.clear-filter-btn {
    background: #DFE3E8;
    color: #637381;
    border-radius: 4px;
    padding: 1px 5px;
}

.filter-icon {
    position: relative;
    top: 8px;

    .filter-count {
        padding: 1px 7px;
        border-radius: 20px;
        background: #F26500;
        color: #FFFFFF;
        font-size: 10px;
        font-weight: 500;
        text-align: center;
        position: absolute;
        top: -10px;
        right: 0;
    }
}

.empty-complete {
    text-align: center;
    padding-top: 100px;

    .complete-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #A5B4BF;
        padding-top: 12px;
        padding-bottom: 20px;
    }
}

</style>
